@import "../../../Common/all.scss";

.tournaments {
  padding: 4.8rem 0;
  @include media-min($md) {
    padding: 10rem 0;
  }
  @include media-min($xl) {
    padding: 12.8rem 0;
  }
  .grid {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    h1 {
      text-align: center;
    }
  }
}
