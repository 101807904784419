@import "../../../Common/all.scss";

.tournamentHero {
  padding: 4rem 0;
  border-bottom: 1px solid #424242;

  .topGlassTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -12rem;
    margin-left: 1rem;
    margin-right: 1rem;

    h3 {
      font-size: 1.6rem;
      text-transform: none;
    }
  }
  .glassHero {
   
    background: linear-gradient(248.54deg, rgba(228, 170, 255, 0.065) 1.47%, rgba(255, 255, 255, 0.0416) 100%);
    backdrop-filter: blur(32px);
    border-radius: 26px;
    padding: 25px;
    border-bottom:1px solid rgba(255,255,255,0.5);
    border-top:1px solid rgba(255,255,255,0.5);
  }

  h3 {
    font-size: var(--fs-h3);
    color: $high-emphasis-white;
    text-transform: uppercase;
    margin-bottom: 1.6rem;
  }
  ul {
    display: flex;
    gap: 1.6rem;
    justify-content: space-between;
    margin-bottom: 1.6rem;
    @include media-min($lg) {
      margin-bottom: 3.2rem;
    }
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      h4 {
        font-size: var(--fs-h4);
        color: $medium-emphasis-white;
        text-transform: uppercase;
      }
      p {
        font-size: var(--fs-h4);
        color: $high-emphasis-white;
      }
    }
    li:last-child {
      p {
        color: #b388ff;
      }
    }
  }
  .organisedBy {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    img {
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      border: 2px solid $high-emphasis-white;
      @include media-min($lg) {
        width: 4.8rem;
        height: 4.8rem;
      }
    }
    > div {
      display: flex;
      flex-direction: column;
      h4 {
        font-size: var(--fs-h4);
        color: $medium-emphasis-white;
        text-transform: uppercase;
      }
      p {
        font-size: var(--fs-h4);
        color: $high-emphasis-white;
      }
    }
  }
}
