$high-emphasis-white: rgba(255, 255, 255, 0.87);
$medium-emphasis-white: rgba(255, 255, 255, 0.6);
$low-emphasis-white: rgba(255, 255, 255, 0.37);

$high-emphasis-black: rgba(0, 0, 0, 0.87);
$medium-emphasis-black: rgba(0, 0, 0, 0.6);
$low-emphasis-black: rgba(0, 0, 0, 0.37);

$p-white: rgba(255, 255, 255, 0.7);

$bg: #212121;
$color-primary: #b388ff;
