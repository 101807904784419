@import "../../Common/all.scss";

.hero {
  background: url("./bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    margin-top: 10rem;
  }
  .heroContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    span {
      font-size: 1.3rem;
      color: $high-emphasis-white;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 0.8rem;
      @include media-min($sm) {
        margin-bottom: 2.4rem;
      }
    }
    h1 {
      margin-bottom: 0.8rem;
      text-transform: uppercase;
    }
    p {
     
      color: $high-emphasis-white;
      font-weight: 300;
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 2.4rem;
      @include media-min($sm) {
        margin-bottom: 4rem;
      }
      @include media-min($lg) {
        margin-bottom: 5.2rem;
      }
    }
    button {
      justify-self: flex-start;
      background: none;
      align-self: flex-start;
      transition: ease-out 0.4s;
      border: none;
      width: 54px;
      height: 54px;
      position: relative;
      background-image: linear-gradient(90deg, #7F46F8, #DE6B39, #E8C95C, #88C2C3, #E74E87);
      filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.25));
      border-radius: 100px;
      z-index: 0;

      p {
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 25%;
        transform: translate(-50%, -50%);
        font-size: 1.4rem;
      }

      div {
        position: absolute;
        background-color: #212121;
        left: 0;
        top: 0;
        right: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 54px;
        transition: ease-out 0.4s;
        height: 54px; 
        border: 1.5px solid #FFFFFF;
        filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.25));
        border-radius: 100px;
      }

      //before
      &:before {
        content: "";
        position: absolute;
        top: 1.5px;
        right: 1.5px;
        bottom: 1.5px;
        left: 1.5px;
        /* --- */
        background-color: #212121;
        border-radius: 100px;
        box-sizing: border-box;
        z-index: -1;
      }

      &:hover {
        width: 186px;

        p {
          opacity: 100%;
        }

        div {
          left: auto;
          right: 0;
          transition: ease-out 0.4s;
        }
      }
    }
  }
}
