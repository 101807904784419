@import "../../../Common/all.scss";



.tournamentHero {
  padding: 4rem 0;
  height: 282px;
  border: none;
  border-bottom: 1px solid #424242;

  .containerMd {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .startContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 0.8rem;
      align-items: center;
      justify-content: center;
  
      h1 {
        grid-column-start: 1;
        grid-column-end: 2;
        font-style: normal;
        font-weight: 300;
        font-size: 36px;
        line-height: 42px;
        color: rgba(255, 255, 255, 0.87);
      }
  
      p {
        grid-column-start: 1;
        grid-column-end: 2;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.15em;
        color: rgba(255, 255, 255, 0.6);
  
      }
  
      button {
        grid-column-start: 2;
        grid-row-start: 1;
        grid-row-end: 3;
        width: 183px;
        height: 45px;
        border: 2px solid #B388FF;
        filter: drop-shadow(0px 0px 28px rgba(0, 0, 0, 0.25));
        border-radius: 100px;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.05em;
        color: #B388FF;
        justify-self: flex-end;
        background-color: transparent;
      }
    }
  }

  h3 {
    font-size: var(--fs-h3);
    color: $high-emphasis-white;
    text-transform: uppercase;
    margin-bottom: 1.6rem;
  }
  ul {
    display: flex;
    gap: 1.6rem;
    justify-content: space-between;
    margin-bottom: 1.6rem;
    @include media-min($lg) {
      margin-bottom: 3.2rem;
    }
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      h4 {
        font-size: var(--fs-h4);
        color: $medium-emphasis-white;
        text-transform: uppercase;
      }
      p {
        font-size: var(--fs-h4);
        color: $high-emphasis-white;
      }
    }
    li:last-child {
      p {
        color: #b388ff;
      }
    }
  }
  .organisedBy {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    img {
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      border: 2px solid $high-emphasis-white;
      @include media-min($lg) {
        width: 4.8rem;
        height: 4.8rem;
      }
    }
    > div {
      display: flex;
      flex-direction: column;
      h4 {
        font-size: var(--fs-h4);
        color: $medium-emphasis-white;
        text-transform: uppercase;
      }
      p {
        font-size: var(--fs-h4);
        color: $high-emphasis-white;
      }
    }
  }
}
