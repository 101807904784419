@import "../src/Common/all.scss";
@import "bootstrap/dist/css/bootstrap.min.css";
:root {
  // TYPOGRAPHY
  --ff-body: "Saira", sans-serif;

  --fs-h1: 3.6rem;
  --fs-subtitle: 2rem;

  --fs-h2: 3rem;
  --fs-h3: 1.4rem;
  --fs-h4: 1.2rem;

  --fs-btn: 1.4rem;
  --fs-p: 1.4rem;
  --fs-link: 1.4rem;
  --fs-tab-p: 2.4rem;

  //SPACING
  --section-p: 6.4rem;
  --header-margin: 3.2rem;
}
@include media-min($sm) {
  :root {
    --fs-btn: 1.6rem;
    --fs-h3: 1.8rem;
  }
}
@include media-min($md) {
  :root {
    // TYPOGRAPHY
    --fs-subtitle: 1.8rem;
    --fs-btn: 1.8rem;
    --fs-h4: 1.6rem;
    --fs-p: 1.6rem;

    //SPACING
    --section-p: 10rem;
    --header-margin: 4rem;
  }
}
@include media-min($lg) {
  :root {
    // TYPOGRAPHY
    --fs-subtitle: 2rem;

    --fs-h3: 2.4rem;
    --fs-h4: 2rem;
    --fs-h1: 3.2rem;

    //SPACING
    --section-p: 15rem;
    --header-margin: 5.6rem;
  }
}
@include media-min($xl) {
  :root {
    --fs-subtitle: 2.4rem;
    // TYPOGRAPHY
    --fs-h1: 4.8rem;
    --fs-h2: 3.6rem;
    --fs-h3: 2.8rem;
    //SPACING
    --section-p: 20rem;
    --header-margin: 6.4rem;
  }
}
html {
  box-sizing: border-box;
  font-size: 10px;
  // scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
dl,
dd {
  margin: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  display: block;
}

html {
  box-sizing: border-box;
}
html,
body {
  line-height: 1.5;
}

body {
  font-family: var(--ff-body);
}
.container-md {

  .tournament-title {
    margin-bottom: 4.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      width: 30%;
    }
  }

  .title-bracket {
    margin-top: 8rem;
    margin-bottom: 3.2rem;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
  }
  

  @include media-max($md) {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  @include media-max($sm) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

footer {
  border-top: 1px solid #424242;
  padding-top: 9.6rem;
  padding-bottom: 9.6rem;
  background: url("../public/footer.svg");
  background-size: cover;
}

section {
  border-top: 1px solid #424242;
}

header {

  //sticky
  position: absolute;
  width: 100%;
  margin-top: 25px;

  .container-md {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .col1 {
      height: 9.6rem;
      display: flex;
      align-items: center;
      
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.05em;
        color: white;
        margin: 1rem;
      }
    }

    .col2 {
      .user-name {
        gap: 2rem;
        .user-logged {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.8rem;

          span {
            img {
              width: 3.2rem;
              height: 3.2rem;
              border-radius: 50%;
              border: 2px solid $high-emphasis-white;
              @include media-min($lg) {
                width: 4.8rem;
                height: 4.8rem;
              }
            }
          }

          h4 {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.15em;
          }
        }
       
        button {
          width: 40px;
          height: 40px;
          background: linear-gradient(248.54deg, rgba(151, 7, 217, 0.13) 1.47%, rgba(255, 255, 255, 0.0416) 100%);
          backdrop-filter: blur(32px);
          border-radius: 8px;
          border: 1px solid transparent;
          //border-image: linear-gradient(to top, #AE80FF, transparent);
          background-image: linear-gradient(#212121, #212121), 
          linear-gradient(to top, #AE80FF, transparent);
          background-origin: border-box;       
          background-clip: content-box, border-box;
          border-image-slice: 1;
          color: rgba(255, 255, 255, 0.87);
          font-size: 22px;
        }
        .dropdown {
          position: absolute;
          top: 100%;
          right: 0;
          width: 300px;
          z-index: 2;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 32px;
          margin-top: 1.8rem;
          gap: 24px;
          background: linear-gradient(248.54deg, rgba(151, 7, 217, 0.15) 1.47%, rgba(255, 255, 255, 0.048) 100%);
          backdrop-filter: blur(32px);
          border: 1px solid transparent;
          border-image: linear-gradient(to top, #AE80FF, transparent);
          border-image-slice: 1;
          color: rgba(255, 255, 255, 0.87);
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.05em;

          button.login {
            width: auto;
            height: auto;
            background: linear-gradient(144.08deg, #B388FF -23.22%, #6200EA 46.22%);
            box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
            border-radius: 100px;
            align-self: stretch;
            flex-grow: 0;
            padding: 8px 16px;
            border: none;
            color: rgba(255, 255, 255, 0.87);
            font-size: 14px;
          }

          button.core-login {
            width: auto;
            height: auto;
            border: 2px solid #B388FF !important;
            filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.25));
            border-radius: 100px;
            align-self: stretch;
            flex-grow: 0;
            padding: 6px 16px;
            border: none;
            color: #B388FF;
            background: transparent;
            font-size: 14px;

            a {
              text-decoration: none;
              color: #B388FF;
            }
          }
        }

        .dropdown-close {
          display: none;
        }
      }
    }
  }
}

body {
  background: $bg;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.user-name {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  align-items: center;
  position: relative;
  span {
    svg {
      width: 35px;
      path {
        stroke: #1de9b6;
      }
    }
  }
  h4 {
    font-size: var(--fs-link);
    color: $high-emphasis-white;
    //text-transform: capitalize;

    letter-spacing: 2px;
  }
  .profile-dropdown {
    padding: 0.8rem 2rem;
    background-color: #1f2244;
    color: $high-emphasis-white;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    display: none;
    border-radius: 0.8rem;
    span {
      cursor: pointer;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.05em;
    }
  }
}
.user-name:hover .profile-dropdown {
  display: flex;
}


.modal-layout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  z-index: 100;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .login-modal {
    background: linear-gradient(121.38deg, #424242 0%, #121212 100%);
    box-shadow: 0px 0px 32px #000000;
    padding: 4rem;
    border-radius: 4rem;
    max-width: 768px;
    max-height: 90vh;
    overflow: scroll;
    @include media-max($md) {
      max-width: 330px;
    }
  }
}

.login-form {
  width: 350px;

  @include media-max($md) {
    width: 100%;
  }

  h2 {
    font-size: var(--fs-h3);
    color: $high-emphasis-white;
    font-weight: 400;
    margin-bottom: 0.8rem;
  }

  > p {
    font-size: var(--fs-p);
    margin-bottom: 2.4rem;
    color: $high-emphasis-white;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    input {
      background: #ffffff;
      box-shadow: -2px -2px 8px #606060, 2px 2px 8px #424242,
        inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 21px;
      height: 42px;
      border: none;
      width: 100%;
      font-size: 1.6rem;
      padding: 1rem 2rem;
    }

    button {
      margin-top: 1.6rem;
      margin-bottom: 1.6rem;
    }
  }

  .login-footer {
    p {
      font-size: var(--fs-link);
      color: $medium-emphasis-white;
      text-align: center;

      a {
        color: $high-emphasis-white;
        text-decoration: underline;
      }
    }
  }
}

h1 {
  font-size: var(--fs-h1);
  color: $high-emphasis-white;
  font-weight: 300;
}

.btn-primary {
  cursor: pointer;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  outline: none;
  border: none;
  color: $high-emphasis-white;
  padding: 1rem 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;
  font-weight: 500;
  font-size: var(--fs-p);
  background: linear-gradient(144.08deg, #b388ff -23.22%, #6200ea 46.22%);
  @include media-min($md) {
    padding: 1.4rem 2.8rem;
  }
}

.nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-bottom: none;
  .nav-item {
    button {
      width: 100%;
      font-weight: 500;
      font-size: 16px;
      color: $medium-emphasis-white;
      padding: 1rem;
      border: none;
      border-color: transparent;
    }
    .nav-link {
      border: 1px solid transparent;

      &:hover {
        border: none;
        border-bottom: 1px solid #ae80ff;
        border-right: 1px solid #ae80ff;
      }
    }
    .nav-link.active {
      background: linear-gradient(
        248.54deg,
        rgba(151, 7, 217, 0.13) 1.47%,
        rgba(255, 255, 255, 0.0416) 100%
      );
      backdrop-filter: blur(32px);
      border: 1px solid transparent;
      border-image: linear-gradient(to top, #AE80FF, transparent);
      border-image-slice: 1;
      color: $medium-emphasis-white;
    }
  }
}

footer {
  //   background-image: url(https://espl.gg/assets/circle-pattern.png);
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   background-position-y: bottom;
  background-color: transparent;
  .container-md {
    h2 {
      text-align: left;
      color: $high-emphasis-white;
    }
    h4 {
      font-weight: 300;
      font-size: 32px;
      line-height: 38px;
      color: $high-emphasis-white;
      margin-bottom: 4rem;
      @media only screen and (max-width: 1200px) {
        font-size: 28px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 30px;
      }
    }
    .contact-options {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 5rem;
      margin-bottom: 4rem;
      a {
        .contact-option {
          border-radius: 1rem;
          padding: 4.8rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
          color: $high-emphasis-white;
          transition: all 0.3s ease-in;
          @media only screen and (max-width: 600px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 2.4rem;
          }
          .button-footer {
            outline: none;
            border: none;
            padding: 1.6rem 3.2rem;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
            border-radius: 3.5rem;
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            color: $high-emphasis-white;
            transition: all 0.2s ease-in;
            cursor: pointer;
            background-color: #651fff;
            text-align: center;

            @media only screen and (max-width: 768px) {
              font-size: 18px;
              line-height: 28px;
            }
            @media only screen and (max-width: 600px) {
              width: 100%;
            }
          }
          .contact-option-name {
            display: flex;
            align-items: center;
            gap: 2.4rem;
            svg {
              width: 48px;
              height: 48px;
            }
            p {
              font-weight: normal;
              font-size: 24px;
              line-height: 28px;
              color: $high-emphasis-white;
              margin: 0;
              cursor: pointer;
            }
          }
        }
        .email-us {
          background-color: #651fff;
          .button-footer {
            background-color: #651fff;
            text-align: center;
          }
        }
        .discord-chat {
          background-color: #5865f2;
          .button-footer {
            background-color: #5865f2;
            text-align: center;
          }
        }
        &:hover {
          text-decoration: none;

          .contact-option {
            filter: brightness(90%);
          }
        }
      }

      @media only screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
        gap: 3rem;
      }
    }
    .footer-primary-links {
      display: grid;
      grid-template-columns: repeat(4, auto);
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4rem;
      @media only screen and (max-width: 1200px) {
        grid-template-columns: repeat(3, auto);
        justify-content: unset;
        row-gap: 4rem;
        .social-media-col {
          grid-column: 2/3;
          justify-self: center;
        }
        .link-col-2 {
          justify-self: center;
        }
        .link-col-3 {
          justify-self: end;
        }
      }

      @media only screen and (max-width: 768px) {
        grid-template-columns: auto;
        justify-content: center;
        .social-media-col {
          grid-column: unset;
        }
        justify-items: center;
        .link-col-3 {
          justify-self: unset;
        }
      }
      .primary-link-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-items: flex-start;
        p {
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: $high-emphasis-white;
          margin: 0;
        }
        a {
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          text-decoration-line: underline;
          color: $high-emphasis-white;
        }
      }
      .social-media-col {
        display: flex;

        align-items: center;
        a {
          svg {
            width: 24px;
          }
          margin-right: 3.2rem;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .twitter {
        svg {
          width: 27px !important;
        }
      }
    }
    .footer-secondary-links {
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        gap: 2.4rem;
        align-items: center;
      }
      .secondary-links {
        display: flex;
        gap: 4rem;
        @media only screen and (max-width: 1200px) {
          gap: 2rem;
        }
        a {
          font-weight: normal;
          font-size: 16px;
          line-height: 25px;
          color: $high-emphasis-white;
        }
        @media only screen and (max-width: 600px) {
          flex-direction: column;
          align-items: center;
          gap: 0.8rem;
        }
      }
      h5 {
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        color: $high-emphasis-white;
      }
    }
  }
}

.dIBoOn:first-of-type {
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border-top-width: 1px !important;
}

.dIBoOn:last-of-type {
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}

.dIBoOn {
  border-right: 1px solid $medium-emphasis-white !important;
  border-left: 1px solid $medium-emphasis-white !important;
}

.jNmcrv {
  height: 1px;
  -webkit-transition: border-color 0.5s;
  transition: border-color 0.5s;
  border-width: 1px;
  border-style: solid;
  border-color: #212121 !important;
}
.hMqIeH {
  border-width: 1px;
  border-color: #212121 !important;
}

.kiQYUq {
  border-right: 1px solid #b388ff !important;
  border-left: 1px solid #b388ff !important;
  border-top: 1px solid #b388ff !important;
  border-bottom: 1px solid #b388ff !important;
  border-radius: 8px !important;
}

.jIYUq {
  border-right: 1px solid #b388ff !important;
  border-left: 1px solid #b388ff !important;
  border-top: 1px solid #b388ff !important;
  border-bottom: 1px solid #b388ff !important;
  border-radius: 8px !important;
}

.gyfLEt {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.sc-eCYdqJ {
  margin: 8px 0;
  font-size: 14px;
}

.pagination {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.87);
  text-decoration: none;
}

.previousLinkA {
  color: rgba(255, 255, 255, 0.87);
  text-decoration: none;
}

.nextLinkA {
  color: rgba(255, 255, 255, 0.87);
  text-decoration: none;
}

.pageNum {
  margin-left: 1.6rem;
  margin-right: 1.6rem;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6rem;
  width: 100%;
  
  h3 {
    font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.87);
  text-decoration: none;
  }
}

.alert-container {
  background: linear-gradient(150.12deg, #424242 0%, #242424 61.36%, #121212 96.56%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 1.6rem;
  gap: 1rem;
  z-index: 999;

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 26px;
    color: rgba(255, 255, 255, 0.87);
    text-decoration: none;
  }

  button {
    background: none;
    border: none;
  }
}

.yellow {

  span {
    color: #ffc107 !important;

    svg {
      path {
        stroke: #ffc107 !important;
      }
    }

  }
  
  img {
    border: 1px solid #ffc107 !important;
  }

}

.green {
  span {
    color: cyan !important;

    svg {
      path {
        stroke: cyan !important;
      }
    }

  }
  
  img {
    border: 1px solid cyan !important;
  }
}

.grey {
  
  span {
    color: grey !important;
  
    svg {
      path {
        stroke: grey !important;
      }
    }
  }
  
  img {
    border: 1px solid grey !important;
  }
}

.tournament-modal-body {
  background: linear-gradient(248.54deg, rgba(228, 170, 255, 0.065) 1.47%, rgba(255, 255, 255, 0.0416) 100%);
  box-shadow: 20px 20px 50px rgba(0,0,0,0.5);
  backdrop-filter: blur(50px);
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 12px;
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 10rem;
  z-index: 0;
  border-bottom:1px solid rgba(255,255,255,0.5);
  border-left:1px solid rgba(255,255,255,0.5);
  // border-right:1px solid rgba(255,255,255,0.5);
  .content {
    width: 100%;

    h3 {
      font-weight: 300;
      font-size: 24px;
      line-height: 30px;
      color: rgba(255, 255, 255, 0.87);
      padding-bottom: 2.4rem;
    }

    form {
      display: flex;
      flex-direction: column;
      // min-width: 350px;
      gap: 1.6rem;

      h3 {
        margin-top: 2.4rem;
        margin-bottom: -1.2rem;
        padding-bottom: 0;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.38);
        margin-bottom: 2.4rem;
      }
      .score-form {
        color: white;
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
  
        .items {
          display: flex;
          flex-direction: row;
          gap: 1.6rem;
          align-items: center;
          justify-content: space-between;
  
          input {
            width: 40px;
            border-radius: 5px;
            text-align: center;
          }
        }
      }
    }
  }
}

.tournament-modal-body-score {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  
  // border-right:1px solid rgba(255,255,255,0.5);
  .content {
    background: linear-gradient(248.54deg, rgba(228, 170, 255, 0.065) 1.47%, rgba(255, 255, 255, 0.0416) 100%);
    box-shadow: 20px 20px 50px rgba(0,0,0,0.5);
    backdrop-filter: blur(50px);
    border-radius: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30rem;
    padding: 40px;
    gap: 12px;
    margin-top: 4rem;
    margin-bottom: 10rem;
    z-index: 0;
    border-bottom:1px solid rgba(255,255,255,0.5);
    border-left:1px solid rgba(255,255,255,0.5);

    .x {
      position: absolute;
      top: 1.8rem;
      right: 1.8rem;
    }

    h3 {
      font-weight: 300;
      font-size: 24px;
      line-height: 30px;
      color: rgba(255, 255, 255, 0.87);
      padding-bottom: 2.4rem;
      padding-top: 1.4rem;
    }

    form {
      display: flex;
      flex-direction: column;
      // min-width: 350px;
      gap: 1.6rem;
      width: 100%;

      h3 {
        margin-top: 2.4rem;
        margin-bottom: -1.2rem;
        padding-bottom: 0;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.38);
        margin-bottom: 2.4rem;
      }
      .score-form {
        color: white;
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
  
        .items {
          display: flex;
          flex-direction: row;
          gap: 1.6rem;
          align-items: center;
          justify-content: space-between;
  
          input {
            width: 60px;
            border-radius: 5px;
            text-align: center;
          }
        }
      }
    }
  }
}

input,
select {
  background: #ffffff;
  box-shadow: -2px -2px 8px #606060, 2px 2px 8px #424242,
    inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 21px;

  width: 100%;
}
input,
select {
  padding: 0.8rem 1.4rem;
  font-size: 16px;
  border: 1px solid #d6d1d5;
}
