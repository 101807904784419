@import "../../../Common/all.scss";


.itemStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex: 100%;
}

.participantTitle {
  margin-top: 5.0rem;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 0.8rem;
  color: rgba(255, 255, 255, 0.87);
}

.participantSub {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15em;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 3.2rem;
}

.containerMd {
  border-radius: 0.6rem;
  border: 1px solid #424242;
  overflow: hidden;
}

.gButton {
  width: 183px;
  height: 45px;
  border: 2px solid #B388FF;
  filter: drop-shadow(0px 0px 28px rgba(0, 0, 0, 0.25));
  border-radius: 100px;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  color: #B388FF;
  justify-self: flex-end;
  background-color: transparent;
}

.inputDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 3.2rem;
  margin-bottom: 5rem;
}

.form {
  width: 100%;
  position: relative;
  .formButton {
    position: absolute;
    right: 10px;
    top: 0.9rem;
    border: none;
    background: transparent;
  }

}