@import "../../../Common/all.scss";

article {
    background: linear-gradient(248.54deg, rgba(228, 170, 255, 0.065) 1.47%, rgba(255, 255, 255, 0.0416) 100%);
    box-shadow: 0px 4px 4px rgba(33, 33, 33, 0.2);
    -webkit-backdrop-filter: blur(32px);
    backdrop-filter: blur(32px);
    border-radius: 26px;
    border: 1px solid transparent;
    background-image: linear-gradient(#313131, #313131), 
          linear-gradient(to top, #777777, transparent);
    background-origin: border-box;       
    background-clip: content-box, border-box;
    border-image-slice: 1;
  a {
    text-decoration: none;
    .grid {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.2rem;

    // box-shadow: 0px 4px 4px rgba(33, 33, 33, 0.2);
    // backdrop-filter: blur(32px);
    
    // border: 1px solid transparent;
    // background-image: linear-gradient(#212121, #212121), 
    //       linear-gradient(to top, #424242, transparent);
    

    
          
      @include media-min($sm) {
        padding: 1.6rem 2.4rem;
      }
      @include media-min($xl) {
        padding: 1.8rem 2.4rem;
      }
      .content {
        display: flex;
        gap: 2.4rem;
        align-items: center;

        img {
          border: 2px solid gold;
          border-radius: 100px;
          width: 64px;
        }
        
        .flexCol {
          display: flex;
          flex-direction: column;
          h3 {
            font-size: var(--fs-h3);
            color: $high-emphasis-white;
          }
          > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              color: #ffc107;
              font-size: 1.2rem;
              @include media-min($xl) {
                font-size: 1.4rem;
              }
            }
            > div {
              display: flex;
              align-items: center;

              span {
                display: flex;
                align-items: center;
              }
              span:last-child {
                margin-top: 0.4rem;
              }
            }
          }
          .statusGrid {
            display: flex;
            gap: 14rem;
            margin-top: 0.8rem;
          }
        }
      }
    }
  }
}

.actions {
  display: flex;
  gap: 2.4rem;
  //   span:first-child {
  //     svg {
  //       width: 3.6rem;
  //       path {
  //         fill: $medium-emphasis-white;
  //       }
  //     }
  //   }
  span:last-child {
    display: flex;
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    // background: linear-gradient(248.54deg, rgba(228, 170, 255, 0.065) 1.47%, rgba(255, 255, 255, 0.0416) 100%);
    background: linear-gradient(150.12deg, #424242 0%, #242424 61.36%, #121212 96.56%);
    // box-shadow: 0px 4px 4px rgba(33, 33, 33, 0.2);
    // backdrop-filter: blur(50px);
    // border: 1px solid transparent;
    // background-image: linear-gradient(#212121, #212121), 
    //       linear-gradient(to top, #424242, transparent);
    // background-origin: border-box;       
    // background-clip: content-box, border-box;
    // border-image-slice: 1;
  }
}
