@import "../../../Common/all.scss";

.tournamentHero {
  padding: 4rem 0;
  height: 400px;
  background: url("https://cdn.mos.cms.futurecdn.net/f019dc7632185b727923871019e4cc7a.jpg");
  // background-position: center;
  background-size: contain;
  scale: 50%;
  background-attachment: fixed;
  h3 {
    font-size: var(--fs-h3);
    color: $high-emphasis-white;
    text-transform: uppercase;
    margin-bottom: 1.6rem;
  }
  ul {
    display: flex;
    gap: 1.6rem;
    justify-content: space-between;
    margin-bottom: 1.6rem;
    @include media-min($lg) {
      margin-bottom: 3.2rem;
    }
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      h4 {
        font-size: var(--fs-h4);
        color: $medium-emphasis-white;
        text-transform: uppercase;
      }
      p {
        font-size: var(--fs-h4);
        color: $high-emphasis-white;
      }
    }
    li:last-child {
      p {
        color: #b388ff;
      }
    }
  }
  .organisedBy {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    img {
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      border: 2px solid $high-emphasis-white;
      @include media-min($lg) {
        width: 4.8rem;
        height: 4.8rem;
      }
    }
    > div {
      display: flex;
      flex-direction: column;
      h4 {
        font-size: var(--fs-h4);
        color: $medium-emphasis-white;
        text-transform: uppercase;
      }
      p {
        font-size: var(--fs-h4);
        color: $high-emphasis-white;
      }
    }
  }
}
